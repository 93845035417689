<template>
  <div :class="{ 'table-responsive': scrolled, 'wet-table-no-wrap': nowrap }">
    <table
      class="table table-sm table-hover wet-table"
      :class="{
        'wet-table-flex': bodyHeight,
        'loading': loading,
      }"
    >
      <thead>
        <tr class="">
          <slot name="head">
            <th v-if="radio"></th>
            <th
              v-for="(h, i) in hcols"
              :key="i"
              class="empty-ph"
              :class="{
                [head[hcols[i]].classes]: true,
                [`text-${head[hcols[i]].align}`]: head[hcols[i]].align,
                [`text-${head[hcols[i]].color}`]: head[hcols[i]].color,
              }"
              :colspan="head[hcols[i]].colspan"
            >
              {{ head[hcols[i]].text }}
            </th>
          </slot>
        </tr>
      </thead>
      <transition-group
        name="list-vert"
        tag="tbody"
        :style="{
          'maxHeight': bodyHeight ? `${bodyHeight}px` : null,
        }"
      >
        <slot name="body">
          <tr
            v-for="(b, i) in body"
            :key="i"
            :class="{
              'font-weight-bold': i === body.length - 1 && lastIsTotal,
              'bg-secondary-lighten': i === body.length - 1 && lastIsTotal,
              'text-secondary-darken': i === body.length - 1 && lastIsTotal
            }"
            @click="$emit('update:selected', i)"
          >
            <th v-if="radio">
              <div class="pt-1">
                <label
                  :id="`wet-table-select-${i}`"
                  :for="`wet-table-select-checkbox-${i}`"
                  class="radio is-size-7"
                >
                  <input
                    type="radio"
                    :checked="selected === i"
                    @click="$emit('update:selected', i)"
                    :id="`wet-table-select-checkbox-${i}`"
                  >
                  <span id="wet-done-span-confirm"></span>
                </label>
              </div>
            </th>
            <template v-for="(bd, ii) in bcols">
              <td :key="ii" :class="{
                [`text-${head[bcols[ii]] && head[bcols[ii]].align}`]: head[bcols[ii]]
                  && head[bcols[ii]].align,
                [b[bcols[ii]].classes]: !!b[bcols[ii]].classes,
              }">
                <slot
                  v-if="$scopedSlots[`ceil-${bcols[ii]}`]"
                  :name="`ceil-${bcols[ii]}`"
                  :column="bcols[ii]"
                  :row="b"
                  :item="b[bcols[ii]]"
                >
                  {{b[bcols[ii]].text}}
                </slot>
                <slot v-else name="ceil" :column="bcols[ii]" :item="b[bcols[ii]]" :row="b">
                  {{b[bcols[ii]].text}}
                </slot>
              </td>
            </template>
          </tr>
        </slot>
      </transition-group>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    cols: Array,
    bodyHeight: Number,
    head: Object,
    body: Array,
    selected: Number,
    radio: Boolean, /** show radio button col */
    lastIsTotal: Boolean, /** highlight last row */
    loading: Boolean,
    scrolled: Boolean,
    nowrap: Boolean,
  },
  computed: {
    hcols() {
      return Object.keys(this.head || {});
    },
    bcols() {
      return !this.cols || this.cols.length === 0 ? this.hcols : this.cols;
    },
  },
};
</script>
<style lang="scss">
  .table {
    color: var(--senary);
  }
  .wet-table {
    tbody {
      overflow:auto;
      box-shadow:
        inset 0 .75rem .75rem -.75rem rgba($color: #000000, $alpha: .15),
        inset 0 -.75rem .75rem -.75rem rgba($color: #000000, $alpha: .15);
    }
    thead, tbody tr {
      // display:table;
      width:100%;
      transition: all .2s ease-in-out;

      &.selected {
        color: white;
        background-color: var(--secondary);

        &+tr td, &+tr th, td, th {
          border-color: var(--secondary)!important;
        }
      }
    }
    thead th {
      width: auto;
      border: none;
    }
  }
  .wet-table-flex {
    thead, tbody {
      display: block;

      tr {
        display: flex;

        &>* {
          flex: 1;

          &:not(:last-child) {
            flex: 0 1 auto;
          }
        }
      }
    }
  }
  .wet-table-have-been th,
  .wet-table-have-been td,
  .wet-table-have-been+tr th,
  .wet-table-have-been+tr td {
    border: none;
  }
  .wet-table-have-been {

    th, td {
      padding: 0;

      &>div {
        padding: 0.3rem;
        border-radius: 6px;
        border: 1px solid var(--primary);
      }
    }
  }
  .table-hover tbody tr.wet-table-have-been:hover {
    background-color: #{"rgba(var(--primary-rgb), 0.1)"};
    color: hsl(318, 80%, 20%);
  }
  .table-hover tbody>div:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .table-hover tbody>div>tr:hover {
    background-color: transparent;
  }
  .table.loading {
    position: relative;

    tbody::before {
      content: '';
      display: block;
      min-height: 6rem;
    }

    tbody::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      margin: 2rem;
      width: 2rem;
      height: 2rem;
      border-radius: var(--border-radius);
      border-color: var(--primary);
      border-top-color: transparent;
      border-style: solid;
      border-width: 2px;
      animation: tablload .75s linear infinite;
    }

    @keyframes tablload {
      from {
        transform: translateX(calc(-50% - 2rem)) rotateZ(0deg);
      }

      to {
        transform: translateX(calc(-50% - 2rem)) rotateZ(360deg);
      }
    }
  }
  .wet-table-no-wrap {
    * {
      white-space: nowrap;
    }
  }
</style>
